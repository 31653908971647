export const MESSAGE_STRINGS = {
  // App Wrapper Labels
  'GeneralDropdown.default.placeholder': 'Select',
  'Actions.permissions.doYouWishToDelete': 'Do you wish to delete',

  // Pagination Footer
  'GeneralTable.Pagination.caption.label1': 'Displaying',
  'GeneralTable.Pagination.caption.label2': 'of',
  'GeneralTable.Pagination.caption.label3': 'results',
  'GeneralTable.Pagination.caption.rowsLabel': 'Rows per page',
  'GeneralTable.Pagination.caption.noData': 'No data found',

  // Crews
  'GeneralSettings.Crews.sectionHeader': 'Crews',
  'GeneralSettings.Crews.column.header.crewName': 'Crew Name',
  'GeneralSettings.Crews.column.header.actions': 'Actions',
  'GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.crewNameShouldUnique':
    'Crew Names Should be Unique',
  'GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.crewNameLength':
    'Please complete the required field.',
  'GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.failedUniqueNameGeneration':
    'Please re-assign a unique name',
  'GENERAL_CONFIGURATIONS.CREWS.NOTIFICATION.customTitle': 'Delete Crew ?',
  'GENERAL_CONFIGURATIONS.CREWS.NOTIFICATION.customText.inProgress':
    'Crew is being deleted...',
  'GENERAL_CONFIGURATIONS.CREWS.NOTIFICATION.customText':
    'Do you wish to delete this Crew?',
  'GENERAL_CONFIGURATIONS.CREWS.NOTIFICATION.noteText':
    'Note: Deleting crew will affect rotation screen and will also delete all assigned crews.',
  'GENERAL_CONFIGURATIONS.CREWS.MESSAGES.deleteCrewSuccess':
    'Deleted Crew Member Successfully',
  'GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.deleteCrewFailed':
    'There was a problem deleting crew member',
  'GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.updateCrewFailed':
    'There was a problem updating crew members',
  'GENERAL_CONFIGURATIONS.CREWS.ADD.errorsPresent':
    'Remove errors before adding new crew members',
  'Crews.tabHeading': 'Crews',

  // General Configurations
  'GENERAL_CONFIGURATIONS.NOTIFICATION.cancelText': 'No',
  'GENERAL_CONFIGURATIONS.NOTIFICATION.confirmText': 'Yes',

  // General Error Messages
  'ERROR_MESSAGES.emptyField': 'Required Field',
  'ERROR_MESSAGES.fixAllErrorsBeforeProceed':
    'Please resolve the errors before proceeding.',
  'ERROR_MESSAGES.mustBeUnique': 'Values must be unique.',
  // Schedule
  'Schedule.tabHeading': 'Schedule',
  'Schedule.ProductionDay.tableHeader.startTime': 'Start Time',
  'Schedule.ProductionDay.tableHeader.endsOn': 'Ends On',
  'Schedule.ProductionDay.tableHeader.endTime': 'End Time',
  'Schedule.ProductionDay.tableHeader.timezone': 'Timezone',
  'Schedule.ProductionDay.tableCell.productionDay': 'Production Day',
  'Schedule.Shifts.heading': 'Shifts',
  'Schedule.Shifts.tableHeader.shiftName': 'Shift Name',
  'Schedule.Shifts.tableHeader.startTime': 'Start Time',
  'Schedule.Shifts.tableHeader.endTime': 'End Time',
  'Schedule.Shifts.tableHeader.action': 'Action',
  'Schedule.Error.requiredField': 'Required Field',
  'Schedule.Error.invalidTime': 'Invalid time',
  'Schedule.configShifts.legends.productionDayStart': 'Production Day Start',
  'Schedule.configShifts.legends.productionDayEndTime': 'Production Day End',
  'Schedule.configShifts.legends.calendarChangeTime': 'Calendar Day Change',
  'Schedule.ShiftTimeError.sameShiftTime': 'Shift must have different timings.',
  'Schedule.ShiftTimeError.startTimeError':
    'Shift End Time must be later than Shift Start Time',
  'Schedule.ProdTimeError.startTimeError':
    'End Time should be later than the Start Time',
  'Schedule.ShiftEmpty.ErrorText': 'Please Enter Shift Timings',
  'Schedule.ShiftTimeError.boundError':
    'Shift timings should be within the Production Day.',
  'Schedule.shiftOverlap.Errortext': 'Shift must have different timings.',
  'Schedule.ShiftNameError.empty': 'Shift name cannot be empty',
  'Schedule.ShiftNameError.duplicate': 'Shift names cannot be duplicate',
  'Schedule.NOTIFICATION.customTitle': 'Delete Shift?',
  'Schedule.endsOn.sameDay': 'Same Calendar Day',
  'Schedule.endsOn.nextDay': 'Next Calendar Day',
  'Schedule.error.endsOn': 'Ends On cannot be empty',
  'Schedule.error.timezone': 'Timezone cannot be empty',
  'Schedule.error.emptyShift': 'Please add one shift before proceeding',
  'Schedule.fieldCannotBeBlank': 'Field cannot be blank',
  'Schedule.ShiftTimeError.invalidTime': 'Invalid Time',
  'Schedule.productionDay.lessThanProductionDayStartTime':
    'End Time should be between 00:00 and the Production Days Start Time',
  'Schedule.updateScheduleModal.customText':
    'Do you wish to update the Schedule?',
  'Schedule.updateScheduleModal.customNote.1':
    'Note: The changes made to the production schedule will reflect across the system starting the next calendar day.',
  'Schedule.updateScheduleModal.customNote.2':
    'Note: The updated Production Day will go into effect at the Start Time of the next Production Day.',
  'Schedule.updateProductionDay':
    'The updated Production Day will go into effect at the Start Time of the next Production Day',
  // Rotations
  'Rotations.tabHeading': 'Rotations',

  // Weekly and Daily Rotations
  'WeeklyAndDailyRotations.tabHeading': 'Weekly and Daily Rotations',

  // Equipment Intelligence
  'EquipmentIntelligence.tabHeading': 'Equipment Intelligence',
  'EquipmentIntelligence.fileUpload.popover.content':
    'Equipment Intelligence describes the equipment hierarchy of a factory through the Area, Line, Zone, Cell, Asset, Sub-asset down to the component level. Use the template to provide the equipment hierarchy details of the plant and relevant asset parameter data including parameter tags and parameter tag purposes for various use cases (e.g., Factory Health, OEE, Standard Operating Conditions, Equipment Constraints).',
  'EquipmentIntelligence.fileUpload.tabtitle': 'Equipment Intelligence',
  'EquipmentIntelligence.fileUpload.export': 'Export Data',
  'EquipmentIntelligence.primaryUploadTitle':
    '	Upload Equipment Intelligence XLSX',
  'EquipmentIntelligence.tooltip.upload.content':
    'If you have already uploaded an XLSX file, clicking this icon again will allow you to upload a new XLSX file that will replace the previous file. All data from the first file will be removed.',
  'EquipmentIntelligence.upload.success':
    '	File has been successfully uploaded!',
  'EquipmentIntelligence.onNextClick.errorMessage':
    'Please upload Equipment Intelligence XLSX file to before clicking on Next.',
  'EquipmentIntelligenec.reUploadModal.customText':
    'Uploading a new file will erase the previously saved Equipment Intelligence data from the system and replace it with the data in the new file upload.',

  // Plant Financial Calculations
  'GPFC.pageHeading.heading': 'Plant Financial Calculations',
  'GPFC.errors.validMoneyAmount':
    'Enter a valid number with 2 decimal places (eg: 12.50)',
  'GPFC.errors.number': 'Value Must be a Number',
  'GPFC.errors.zero': 'Values should be greater than zero',
  'GPFC.errors.submit.errorsPresent': 'Please fix the errors and resubmit',
  'GPFC.accessor.label': 'Label',
  'GPFC.accessor.value': 'Value',
  'GPFC.headers.uom.part1': 'Cost Per',
  'GPFC.headers.uom.part2': 'of Finished Goods',
  'GPFC.accessor.contribution.label': 'Per',
  'GPFC.heading.parameters': 'Parameters',
  'GPFC.heading.isPlantSoldout': 'Is The Plant Sold Out? ',
  'GPFC.heading.contributionMargin': 'Plants Contribution Margin',
  'GPFC.heading.scrapPercentage': 'Average Scrap Percentage',
  'GPFC.heading.variableCosts': 'Variable Costs',
  'GPFC.tooltip.contributionMargin': 'Pre-tax profits per unit sold',
  'GPFC.tooltip.isPlantSoldout':
    'The state of a manufacturing plant when there is sufficient demand to ensure that every new unit produced will be sold.',
  'GPFC.tooltip.scrapPercentage':
    'A ratio of expected finished goods with quality defects over total finished goods produced over a given period of time.',

  'GPFC.plantSoldout.options.true': 'Yes',
  'GPFC.plantSoldout.options.false': 'No',

  'GPFC.tooltip.row.5':
    'Variable cost is the sum of labour, material, utility and miscellaneous variable costs',

  'GPFC.label.UoM': 'UoM',

  'GPFC.modal.title': 'Confirm!',
  'GPFC.modal.label':
    'Changing UoM will delete all previously entered data for Throughput, WIP, and Ideal Run Rate.',
  'GPFC.modal.confirmText': 'Do you wish to change UoM?',
  'GPFC.label.DOLLAR': 'Dollars ($)',
  'GPFC.label.UNIT': 'Units',
  'GPFC.label.POUND': 'Pounds (lbs)',
  'GPFC.label.row.UoM':
    'Unit of Measure (UoM) of Finished Goods – used for Throughput, WIP, and Ideal Run Rate',
  'GPFC.dropdown.placeholder': 'Select',
  'GPFC.label.totalVariableCost': 'Total Variable Cost',
  'GPFC.label.hourlyAssociates': 'Labor Cost, Hourly Associates',
  'GPFC.label.salariedAssociates': 'Labor Cost, Salaried Associates',
  'GPFC.label.materialCost': 'Material Cost',
  'GPFC.label.utilityCost': 'Utility Cost',
  'GPFC.label.miscellaneousVariableCost': 'Miscellaneous Variable Cost',
  'GPFC.toast.success':
    'Factory General Configuration settings have been successfully completed',

  'PlantFinancialCalculations.tabHeading': 'Plant Financial Calculations',
  // Toast Component
  'Toast.message.SUCCESS': 'Your changes were saved Successfully!',
  'Toast.message.ERROR': 'Oops, something went wrong. Please try again later!',
  'Toast.message.WARNING': 'Oops, something went wrong!',
  'Toast.message.INFO': 'Please wait',

  // Export Upload Component
  'ExportComponent.export.text': 'Export Data',
  'UploadComponent.secondaryUpload.XLSX': 'Upload XLSX',

  'UploadComponent.upload.XLSX': 'Upload Fault Codes XLSX',
  'UploadComponent.reUpload.text': 'Re-upload',

  'UploadComponent.loading': 'Loading Data...',
  'UploadComponent.reUploadModal.customTitle': 'Confirm!',
  'UploadComponent.reUploadModal.noteText': 'Do you wish to Continue?',
  'UploadComponent.reUploadModal.action.YES': 'Yes',
  'UploadComponent.reUploadModal.action.NO': 'No',

  'UploadComponent.reUploadModal.popupMsg':
    'Uploading a new file will replace the current file and any related data throught the application.',
  'UploadComponent.reUploadModal.confirmMsg': 'Would you like to continue?',
  'UploadComponent.reUploadModal.confirmText': 'Confirm!',

  'UploadComponent.reUploadModal.tooltipMsg':
    'If you have already uploaded an XLSX file, clicking this icon again will allow you to upload a new XLSX file that will replace the previous file. All data from the first file will be removed.',

  'SFF.dummyText': 'This is a dummy text 1',
  'TruncateErrorMessage.truncateErrorMessage':
    'Character limit exceeded: Fault Code Number, Faulted Asset, Faulted Sub-Asset, Faulted Component and Faulted Sub-Component values must be 225 characters or less. Your data will truncated.',
  'FaultCodeErrorMessage.faultCodeDescriptionErrorMessage':
    'Character limit exceeded: Fault Code Description value must be 2048 characters or less. Your data will truncated.',
};

export const ERROR_MESSAGES = {
  ACTUALS: {
    LESS_THAN_ERROR: 'Value must be 15 seconds or more',
  },

  NOT_WHOLE_NUMBER: 'Value must be whole numbers',
  REQUIRED: 'Required Field',
  INVALID_VALUE: 'Invalid value entered',
};

export const CONSTANT_MESSAGES = {
  MISC: {
    POST_SUCCESS: 'Your changes have been successfully saved!',
  },
  TARGETS: {
    POST_SUCCESS: 'Your changes have been successfully saved!',
  },
  ACTUALS: {
    POST_SUCCESS: 'Your changes have been successfully saved!',
  },
  FAULT_CODE: {
    FILE_UPLOAD_SUCCESS: 'File has been successfully uploaded!',
  },
  DEFAULT_POST_API_ERROR_MESSAGE: 'Could not update the settings',
  EIP_WARNING_MESSAGE:
    'An update was made to the EI table. Please upload Fault Code data for the new equipment.',
};

export default MESSAGE_STRINGS;
