import React, { useState, createRef, useEffect } from 'react';
import { Grid, Box, styled, Backdrop } from '@mui/material';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { globalstate$ } from '@smf/ui-util-app';
import { useToastContext } from '../../context/toastContext';
import faultCodeUploadWebSocket from '../../utils/websocket';
import { useRxjsState } from '../../utils/useRxjsState';

import infoIcon from '../../assets/img/infoIcon.svg';

import UploadBox from '../../components/UploadBox';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorList from '../../components/ErrorList';
import PopupModalBox from '../../components/PopupModalBox';
import ExportImportDataButton from '../../components/ExportImportDataButton';
import GeneralTooltip from '../../components/GeneralTooltip';

import MESSAGE_STRINGS from '../../constants/en-us';

import {
  formatUploadInformation,
  getParamsToUploadToWebsocket,
  handleWebsocketUploadError,
} from '../../utils/helpers.js';

import {
  queryConstants,
  getFaultCodeTemplateFile,
  retrieveUploadInfo,
} from '../../utils/apihelper';
import {
  FAULTCODE_CONSTANTS,
  ACCORDION_STATUS,
  CONSTRAINT_ACCORDION_TITLES,
} from '../../constants';

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));
const TopSubConfig = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.customColors.wrapperGrey}`,
  borderTop: `1px solid ${theme.customColors.wrapperGrey}`,
}));

const SubConfig = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.customColors.wrapperGrey}`,
}));

// eslint-disable-next-line react/prop-types
const FaultCodesContainer = ({ handleStatus, statusFault }) => {
  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBtnClicked, setIsBtnClicked] = useState(false);
  const [errorList, setErrorlist] = useState([]);
  const [warnList, setWarnList] = useState([]);
  const { rxjsState } = useRxjsState();
  const { toastDispatch } = useToastContext();

  const authToken = globalstate$.value.sessionToken;

  const [
    isDownloadFaultCodeTemplateEnabled,
    setisDownloadFaultCodeTemplateEnabled,
  ] = useState(false);

  const { data: userData } = useQuery(
    [queryConstants.GET_USER_DETAILS],
    async () => {
      const response = await retrieveUploadInfo({
        plantId: rxjsState.factoryId,
      });
      return formatUploadInformation(response);
    },
    {
      enabled: !!rxjsState.factoryId,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isBtnClicked) {
      setIsModalOpen(true);
    } else setIsModalOpen(false);
  }, [isBtnClicked]);
  //isFileAlreadyUploaded GET API getUploadInfo

  //UPLOAD  VALIDATION

  const {
    isLoading: isUploadSocketLoading,
    isError: isUploadSocketError,
    mutate: validateUpload,
  } = useMutation(
    [queryConstants.WS_UPLOAD_VALIDATION],
    async (fileName) => {
      const params = getParamsToUploadToWebsocket(
        rxjsState,
        fileName,
        authToken
      );
      return faultCodeUploadWebSocket(params);
    },
    {
      onError: (error) => {
        handleWebsocketUploadError(
          error,
          handleStatus,
          setErrorlist,
          setWarnList,
          queryClient,
          toastDispatch
        );
      },
      enabled: Boolean(rxjsState.factoryId),
      retry: false,
    }
  );

  useQuery(
    [queryConstants.GET_DOWNLOAD_FILE_DATA],
    async () => {
      const data = await getFaultCodeTemplateFile({
        plantId: rxjsState.factoryId,
      });
      return data;
    },
    {
      enabled: !!(rxjsState.factoryId && isDownloadFaultCodeTemplateEnabled),
      onSuccess: (response) => {
        setisDownloadFaultCodeTemplateEnabled(false);
        window.open(response.data, '_self');
      },
      onError: () => {
        setisDownloadFaultCodeTemplateEnabled(false);
      },
    }
  );

  const downloadFile = () => {
    setisDownloadFaultCodeTemplateEnabled(true);
  };
  const uploadButtonRef = createRef();

  return (
    <>
      <PopupModalBox
        isModalOpen={isModalOpen}
        setModalOpenStatus={setIsBtnClicked}
        ref={uploadButtonRef}
        handleStatus={handleStatus}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
        data-testid="faultcode-container"
      >
        {/* // ? top section with buttons and errors */}
        <TopSubConfig>
          {/* // ? buttons section */}
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ padding: '.5rem 1rem .375rem 0rem' }}
          >
            <Grid item sx={{ paddingRight: '.8rem' }}>
              <ExportImportDataButton
                id="DOWNLOAD EXPORT BUTTON"
                onClick={() => downloadFile()}
                type={FAULTCODE_CONSTANTS.DOWNLOAD_UPLOAD_TYPE.DOWNLOAD}
                text={MESSAGE_STRINGS['ExportComponent.export.text']}
              />
            </Grid>
            <Grid item sx={{ paddingRight: '.4rem' }}>
              {statusFault === ACCORDION_STATUS.IN_PROGRESS ? null : (
                <ExportImportDataButton
                  id="UPLOAD BUTTON"
                  type={FAULTCODE_CONSTANTS.DOWNLOAD_UPLOAD_TYPE.UPLOAD}
                  text={MESSAGE_STRINGS['UploadComponent.secondaryUpload.XLSX']}
                  onClick={() => {
                    if (userData?.isUploaded) {
                      handleStatus(
                        ACCORDION_STATUS.IN_PROGRESS,
                        CONSTRAINT_ACCORDION_TITLES.FAULT_CODE
                      );
                      setIsBtnClicked(true);
                    } else {
                      uploadButtonRef.current.click();
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item>
              {userData?.isUploaded &&
                statusFault === ACCORDION_STATUS.COMPLETED && (
                  <GeneralTooltip
                    title={
                      MESSAGE_STRINGS[
                        'UploadComponent.reUploadModal.tooltipMsg'
                      ]
                    }
                    placement="top-end"
                  >
                    <img src={infoIcon} alt="Error Icons" />
                  </GeneralTooltip>
                )}
            </Grid>
          </Grid>
          {/* // ? error display section */}
          {isUploadSocketError && errorList?.length > 0 && (
            <Grid>
              <ErrorList errorList={errorList} />
            </Grid>
          )}
          {warnList?.length > 0 && (
            <Grid>
              <ErrorList errorList={warnList} warnFlag={true} />
            </Grid>
          )}
        </TopSubConfig>
        <SubConfig>
          {/* // ? main upload button */}
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height:
                errorList?.length < 2
                  ? '32.25rem'
                  : `${32.5 - errorList?.length + 3}rem`,
            }}
          >
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '17.6875rem',
                width: '49.375rem',
                border: (theme) => `1px dashed ${theme.customColors.saveGreen}`,
                borderRadius: '0.5rem',
              }}
            >
              <UploadBox
                errorList={errorList}
                isValidateError={isUploadSocketError}
                validate={validateUpload}
                handleStatus={handleStatus}
                userData={userData}
                ref={uploadButtonRef}
                isFileUploadedWithoutErrors={
                  statusFault === ACCORDION_STATUS.COMPLETED
                }
                setModalOpenStatus={setIsBtnClicked}
                statusFault={statusFault}
                isUploadSocketError={isUploadSocketError}
              />
            </Grid>
          </Grid>
        </SubConfig>
      </Box>
      <Box sx={{ display: 'flex', maxHeight: '100%', flexDirection: 'column' }}>
        <CustomBackdrop open={isUploadSocketLoading}>
          <LoadingIndicator />
        </CustomBackdrop>
      </Box>
    </>
  );
};

export default FaultCodesContainer;
